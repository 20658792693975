define("discourse/plugins/discourse-chat-matrix/discourse/routes/admin-plugins-matrix", ["exports", "discourse/lib/ajax", "discourse/routes/discourse"], function (_exports, _ajax, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsChatRoute extends _discourse.default {
    model() {
      return (0, _ajax.ajax)("/admin/plugins/matrix");
    }
  }
  _exports.default = AdminPluginsChatRoute;
});