define("discourse/plugins/discourse-chat-matrix/discourse/templates/admin-plugins-matrix", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <h1>{{i18n "chat_matrix.synapse_config.title"}}</h1>
  
  <p>{{i18n "chat_matrix.synapse_config.description"}}</p>
  
  <textarea class="matrix-config" readonly>
    {{this.model.synapse_config}}
  </textarea>
  
  <DButton
    @class="btn-danger"
    @icon="sync"
    @label="chat_matrix.regenerate_tokens"
    @action={{action "regenerateTokens"}}
  />
  
  <DButton
    @class="btn-default"
    @icon="far-clipboard"
    @label={{if
      copied
      "chat_matrix.copied_to_clipboard"
      "chat_matrix.copy_to_clipboard"
    }}
    @action={{action "copyToClipboard" this.model.synapse_config}}
  />
  */
  {
    "id": "m0p8UBSf",
    "block": "[[[10,\"h1\"],[12],[1,[28,[35,0],[\"chat_matrix.synapse_config.title\"],null]],[13],[1,\"\\n\\n\"],[10,2],[12],[1,[28,[35,0],[\"chat_matrix.synapse_config.description\"],null]],[13],[1,\"\\n\\n\"],[10,\"textarea\"],[14,0,\"matrix-config\"],[14,\"readonly\",\"\"],[12],[1,\"  \"],[1,[30,0,[\"model\",\"synapse_config\"]]],[1,\"\"],[13],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@class\",\"@icon\",\"@label\",\"@action\"],[\"btn-danger\",\"sync\",\"chat_matrix.regenerate_tokens\",[28,[37,2],[[30,0],\"regenerateTokens\"],null]]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@class\",\"@icon\",\"@label\",\"@action\"],[\"btn-default\",\"far-clipboard\",[52,[30,0,[\"copied\"]],\"chat_matrix.copied_to_clipboard\",\"chat_matrix.copy_to_clipboard\"],[28,[37,2],[[30,0],\"copyToClipboard\",[30,0,[\"model\",\"synapse_config\"]]],null]]],null],[1,[28,[32,0],[\"[[\\\"The `copied` property path was used in the `discourse/plugins/discourse-chat-matrix/discourse/templates/admin-plugins-matrix.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.copied}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"i18n\",\"d-button\",\"action\",\"if\"]]",
    "moduleName": "discourse/plugins/discourse-chat-matrix/discourse/templates/admin-plugins-matrix.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});