define("discourse/plugins/discourse-chat-matrix/discourse/controllers/admin-plugins-matrix", ["exports", "@ember/controller", "@ember/object", "discourse/lib/ajax", "@ember/runloop", "discourse/lib/utilities"], function (_exports, _controller, _object, _ajax, _runloop, _utilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsMatrix extends _controller.default {
    copied = false;
    regenerateTokens() {
      (0, _ajax.ajax)("/admin/plugins/matrix/tokens", {
        type: "POST"
      }).then(model => {
        this.setProperties({
          model
        });
      });
    }
    static #_ = (() => dt7948.n(this.prototype, "regenerateTokens", [_object.action]))();
    copyToClipboard(content) {
      if ((0, _utilities.clipboardCopy)(content)) {
        this.set("copied", true);
        (0, _runloop.later)(() => this.set("copied", false), 3000);
      }
    }
    static #_2 = (() => dt7948.n(this.prototype, "copyToClipboard", [_object.action]))();
  }
  _exports.default = AdminPluginsMatrix;
});